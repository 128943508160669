<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center w-100">
      <b-form-select
        v-model="dateRange"
        :options="dateRanges"
        size="sm"
        label-field="text"
        value-field="id"
        class="w-25"
      />
      <b-tabs
        pills
        content-class="mt-3 ml-2 w-50"
      >
        <b-tab
          v-for="(item, i) in displayRanges"
          :key="i"
          :title="item"
          :active="displayRange === item"
          size="sm"
          :disabled="item === 'monthly' && (dateRange < 30 && dateRange > -1)"
          @click="() => { displayRange = item }"
        />
      </b-tabs>
      <multiselect
        v-model="displayStatus"
        :options="displayStatuses"
        label-title="Highlight status period..."
        text-prop="text"
        value-prop="id"
        size="sm"
        class="w-25"
      />
    </div>
    <client-only>
      <line-chart
        v-if="isFilled && datacollection"
        ref="chart"
        :chart-data="datacollection"
        :options="options"
        class="mt-3 px-3"
      />
    </client-only>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { mapComputedStateFields } from '~/mixins/globalForm'

export default {
  name: 'ChartCredits',
  props: {
    itemId: {
      type: [String, Number],
      required: true
    },
    idx: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      datacollection: null,
      dateRanges: [
        {
          id: '7',
          text: '1 week'
        },
        {
          id: '30',
          text: '1 month'
        },
        {
          id: '90',
          text: '3 month'
        },
        {
          id: '180',
          text: '6 month'
        },
        {
          id: '365',
          text: '1 year'
        },
        {
          id: '-1',
          text: 'Lifetime'
        }
      ],
      dateRange: 30,
      displayRange: 'weekly',
      displayRanges: [
        'daily',
        'weekly',
        'monthly'
      ],
      displayStatuses: [
        {
          value: 'inactive',
          text: 'inactive'
        },
        {
          value: 'active',
          text: 'active'
        },
        {
          value: 'hidden',
          text: 'hidden'
        },
        {
          value: 'register',
          text: 'register'
        },
        {
          value: 'deleted',
          text: 'deleted'
        },
        {
          value: 'suspended',
          text: 'suspended'
        }
      ],
      displayStatus: [],
      isFilled: false
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName'
    }),
    ...mapComputedStateFields('customerEdit', [
      'chartCredits'
    ]),
    chartCreditsDateRange () {
      return this.chartCredits?.filter?.dateRange || 30
    },
    // chartCreditsIsWeekly () {
    //   return this.chartCredits?.filter?.isWeekly || false
    // },
    options () {
      let unit
      let fmt
      switch (this.displayRange) {
        case 'daily':
          unit = 'day'
          fmt = 'D MMM, Y'
          break
        case 'weekly':
          unit = 'week'
          fmt = 'D MMM, Y'
          break
        case 'monthly':
          unit = 'month'
          fmt = 'MMM, Y'
          break
      }
      // this.chartCredits.status
      const ans = []
      for (const i in this.chartCredits.status) {
        const s = this.chartCredits.status[i]
        if (this.displayStatus.includes(s.s)) {
          ans.push({
            type: 'box',
            drawTime: 'beforeDatasetsDraw',
            id: 'a-box-' + i,
            xScaleID: 'x-axis-0',
            yScaleID: 'y-axis-0',
            xMin: s.x0,
            xMax: s.x1,
            yMax: 1000000,
            yMin: 0,
            borderColor: 'rgba(0,0,0,0)',
            borderWidth: 0,
            backgroundColor: s.c,
            onClick () {
              // console.log('CLICK', s.s, moment(s.x0).format('Y-MM-DD HH:mm'), moment(s.x1).format('Y-MM-DD HH:mm'))
              return true
            }
          })
        }
      }
      return {
        events: ['click', 'mousemove', 'mouseout'],
        scales: {
          y: {
            beginAtZero: true
          },
          xAxes: [
            {
              type: 'time',
              display: true,
              time: {
                isoWeekday: true,
                unit
              },
              ticks: {
                drawTicks: true,
                callback (value) {
                  return value
                }
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback (value) {
                  return value
                }
              }
            }
          ]
        },
        legend: {
          display: true
        },
        tooltips: {
          mode: 'index',
          axis: 'x',
          intersect: false,
          callbacks: {
            title: (points) => {
              return moment(points[0]?.label).format(fmt) || ''
            },
            label: (item, chart) => {
              return chart.datasets[item.datasetIndex].data[item.index]?.l || ''
            }
          }
        },
        aspectRatio: 3,
        responsive: true,
        maintainAspectRatio: false,
        annotation: {
          drawTime: 'afterDatasetsDraw', // (default)
          events: ['click', 'mousemove', 'mouseout'],
          dblClickSpeed: 350, // ms (default)
          annotations: ans
        },
        plugins: []
      }
    }
  },
  watch: {
    dateRange (n, o) {
      if (n !== o && n !== null) {
        if ((n < 30 && n > -1) && this.displayRange === 'monthly') {
          this.displayRange = 'weekly'
        }
        this.isFilled = false
        this.$nextTick(async () => {
          await this.getChartCredits({
            itemId: this.itemId,
            dateRange: this.dateRange,
            displayRange: this.displayRange
          })
          this.fillData()
        })
      }
    },
    displayRange (n, o) {
      if (n !== o && n !== null) {
        this.isFilled = false
        this.$nextTick(async () => {
          await this.getChartCredits({
            itemId: this.itemId,
            dateRange: this.dateRange,
            displayRange: this.displayRange
          })
          this.fillData()
        })
      }
    },
    displayStatus (n, o) {
      if (n !== o && n !== null) {
        this.isFilled = false
        this.$nextTick(() => {
          this.fillData()
        })
      }
    },
    chartCreditsDateRange (n) {
      if (n !== null && n !== this.dateRange) {
        this.dateRange = n
      }
    },
    chartCreditsDisplayRange (n) {
      if (n !== null && n !== this.displayRange) {
        this.displayRange = n
      }
    }
  },
  async mounted () {
    await this.getChartCredits({
      itemId: this.itemId,
      dateRange: this.dateRange,
      displayRange: this.displayRange
    })
    this.fillData()
  },
  methods: {
    ...mapActions({
      getChartCredits: 'forms/customerEdit/chartCredits'
    }),
    fillData () {
      this.datacollection = {
        datasets: [
          {
            label: 'Number of Credits spent',
            data: this.chartCredits?.credits?.data || [],
            backgroundColor: this.chartCredits?.credits?.bg,
            borderColor: this.chartCredits?.credits?.border,
            borderWidth: 1
          },
          {
            label: 'Number of Likes',
            data: this.chartCredits?.likes?.data || [],
            backgroundColor: this.chartCredits?.likes?.bg,
            borderColor: this.chartCredits?.likes?.border,
            borderWidth: 1
          }
        ]
      }
      this.$nextTick(() => {
        this.isFilled = true
      })
    }
    // getOptions () {
    //   let unit
    //   let fmt
    //   switch (this.displayRange) {
    //     case 'daily':
    //       unit = 'day'
    //       fmt = 'D MMM, Y'
    //       break
    //     case 'weekly':
    //       unit = 'week'
    //       fmt = 'D MMM, Y'
    //       break
    //     case 'monthly':
    //       unit = 'month'
    //       fmt = 'MMM, Y'
    //       break
    //   }
    //   // this.chartCredits.status
    //   const ans = []
    //   for (const i in this.chartCredits.status) {
    //     const s = this.chartCredits.status[i]
    //     ans.push({
    //       type: 'box',
    //       drawTime: 'beforeDatasetsDraw',
    //       id: 'a-box-' + i,
    //       xScaleID: 'x-axis-0',
    //       yScaleID: 'y-axis-0',
    //       xMin: s.x0,
    //       xMax: s.x1,
    //       yMax: 1000000,
    //       yMin: 0,
    //       borderColor: 'rgba(0,0,0,0)',
    //       borderWidth: 0,
    //       backgroundColor: s.c,
    //       onClick (e) {
    //         // console.log('CLICK', s.s, moment(s.x0).format('Y-MM-DD HH:mm'), moment(s.x1).format('Y-MM-DD HH:mm'))
    //         return true
    //       }
    //     })
    //   }
    //   return {
    //     events: ['click', 'mousemove', 'mouseout'],
    //     scales: {
    //       y: {
    //         beginAtZero: true
    //       },
    //       xAxes: [
    //         {
    //           type: 'time',
    //           display: true,
    //           time: {
    //             isoWeekday: true,
    //             unit
    //           },
    //           ticks: {
    //             drawTicks: true,
    //             callback (value, index, values) {
    //               // console.log('xAxes', value, index, moment(value).format('D'))
    //               return value
    //             }
    //           }
    //         }
    //       ],
    //       yAxes: [
    //         {
    //           ticks: {
    //             beginAtZero: true,
    //             callback (value, index, values) {
    //               return value
    //             }
    //           }
    //         }
    //       ]
    //     },
    //     legend: {
    //       display: true
    //     },
    //     tooltips: {
    //       callbacks: {
    //         title: (points, chart) => {
    //           return moment(points[0]?.label).format(fmt) || ''
    //         },
    //         label: (item, chart) => {
    //           return chart.datasets[item.datasetIndex].data[item.index]?.l || ''
    //         }
    //       }
    //     },
    //     aspectRatio: 3,
    //     responsive: true,
    //     maintainAspectRatio: false,
    //     annotation: {
    //       drawTime: 'beforeDatasetsDraw', // (default)
    //       events: ['click', 'mousemove', 'mouseout'],
    //       dblClickSpeed: 350, // ms (default)
    //       annotations: ans
    //     },
    //     plugins: {
    //     }
    //   }
    // }
  }
}
</script>

<style scoped>

</style>
